<template>
  <pro-layout
    v-model:collapsed="state.collapsed"
    v-model:selectedKeys="state.selectedKeys"
    v-model:openKeys="state.openKeys"
    :loading="isLoading('page-content')"
    :menu-data="menuData"
    :breadcrumb="{ routes: breadcrumb }"
    disable-content-margin
    style="min-height: 100vh"
    iconfont-url="//at.alicdn.com/t/font_2804900_2sp8hxw3ln8.js"
    v-bind="proConfig"
  >
    <template #menuHeaderRender>
      <router-link :to="{ path: '/' }">
        <img src="@/assets/logo.svg?url" v-if="state.collapsed === false" />
        <img src="@/assets/default-avatar.png" v-else />
      </router-link>
    </template>
    <template #rightContentRender v-if="loggedUser">
      <RightContent :current-user="loggedUser" />
    </template>
    <!-- custom breadcrumb itemRender  -->
    <template #breadcrumbRender="{ route, params, routes }">
      <span v-if="routes.indexOf(route) === routes.length - 1">
        {{ route.breadcrumbName }}
      </span>
      <router-link v-else :to="{ path: route.path, params }">
        {{ route.breadcrumbName }}
      </router-link>
    </template>
    <RouterView v-slot="{ Component, route }">
      <transition name="slide-left" mode="out-in">
        <WaterMark
          class="h-full"
          font-color="rgba(0,0,0,.08)"
          :content="`[${loggedUser.code}] ${loggedUser.name}`"
          v-if="loggedUser"
        >
          <component :is="Component" :key="route.path" />
        </WaterMark>
      </transition>
    </RouterView>
  </pro-layout>
</template>

<script setup lang="ts">
import { useRouter, RouterView, RouterLink } from 'vue-router';
import {
  getMenuData,
  clearMenuItem,
  type RouteContextProps,
  WaterMark,
} from '@ant-design-vue/pro-layout';
import { UserOutlined } from '@ant-design/icons-vue';
import { useAuthStore } from '@/store/auth';
import {cannot} from '@/utils/permissions'
import useLoading from '@/hooks/useLoading';

const router = useRouter();
const userStore = useAuthStore();
const beforePrintParams = ref()

const loggedUser = userStore.user;
const filterAuthorized = (routes) => {
  return [...routes].map(route => {
    
    if (cannot(route.meta.permissions)) {
      route.meta.hideInMenu = true
    }

    return route
  })
}

const {menuData} = getMenuData(clearMenuItem(filterAuthorized(router.getRoutes())))

const state = reactive<Omit<RouteContextProps, 'menuData'>>({
  collapsed: false, // default collapsed
  openKeys: [], // defualt openKeys
  selectedKeys: [], // default selectedKeys
});
const { isLoading } = useLoading()
const proConfig = ref({
  layout: 'side',
  navTheme: 'light',
  headerTheme: 'dark',
  fixedHeader: true,
  fixSiderbar: true,
  splitMenus: true,
});
const breadcrumb = computed(() =>
  router.currentRoute.value.matched.concat().map(item => {
    return {
      path: item.path,
      breadcrumbName: item.meta.title || '',
    };
  }),
);

watch(
  router.currentRoute,
  () => {
    const matched = router.currentRoute.value.matched.concat();
    state.selectedKeys = matched.filter(r => r.name !== 'index').map(r => r.path);
    state.openKeys = matched
      .filter(r => r.path !== router.currentRoute.value.path)
      .map(r => r.path);
  },
  {
    immediate: true,
  },
);

window.onbeforeprint = () => {
  beforePrintParams.value = state.collapsed
  state.collapsed = true
}

window.onafterprint = () => {
  state.collapsed = beforePrintParams.value
}

</script>

<style lang="less">
.ant-pro-global-header {
  background-color: #001936;
}


.ant-layout-sider:not(.ant-layout-sider-collapsed) .ant-pro-sider-logo {
  background-color: #001936;
  padding: 8px 16px;
}

.ant-layout-sider.ant-layout-sider-collapsed  .ant-pro-sider-logo {
  padding: 0px;

  & img {
    width: 48px;
    height: 48px;
  }
}

.ant-pro-page-container-children-content {
  margin: 1.5rem;
}

.ant-menu-title-content svg {
  display: inline-block;
}
.ant-pro-menu-item svg + .ant-pro-menu-item-title {
  margin-left: 15px;
}

.ant-menu-submenu-title .ant-menu-item-icon+span {
  margin-left: 15px;
}
</style>
