import type { GenericErrorModel, HttpResponse } from "@/services/api";

export interface PaginationMeta {
    current_page: number
    from: number
    last_page: number
    links: string
    path: []
    per_page: number
    to: number
    total: number
  }

  export interface ResponseLinks {
    first: string,
    last:  string,
    next:  string | null,
    prev:  string | null,
  }

  export interface ResponseData<DataType> {
    data: DataType | null,
    meta?: PaginationMeta,
    links?: ResponseLinks
  }

  export interface IServerResponse<DataType, E> {
    response: HttpResponse<ResponseData<DataType>, E>,
    data: DataType | null,
    body: ResponseData<DataType>
  }

export class ServerResponse<DataType, E> implements IServerResponse<DataType, E> {
    response: HttpResponse<ResponseData<DataType>, E>;
    
    constructor(response: HttpResponse<ResponseData<DataType>, E>) {
        this.response = response
    }

    get data() : DataType | null {
        return this.body?.data || null
    }

    get body() : ResponseData<DataType> {
        return this.response.data
    }
    
    get meta() : ResponseData<DataType> {
        return this.body.meta || {}
    }
}