import '@ant-design-vue/pro-layout/dist/style.css'
import 'ant-design-vue/dist/antd.variable.min.css'

import { createApp, ref } from 'vue'
import { ConfigProvider, message } from 'ant-design-vue'
import ProLayout, { PageContainer } from '@ant-design-vue/pro-layout'
import router from './router'
import App from './App.vue'
import { createPinia } from 'pinia'
import dayjs from 'dayjs'
import calendar from 'dayjs/plugin/calendar'
import zhCN from 'dayjs/locale/zh-cn'
import updateLocale from 'dayjs/plugin/updateLocale'
import relativeTime from 'dayjs/plugin/relativeTime'
import * as menuIcons from '@/assets/menu-icon'
import { useAuthStore } from './store/auth'
import { default as LaravelPermissionToVueJS, setupPermissionAndRole } from '@/utils/permissions'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'
import BugsnagPerformance from '@bugsnag/browser-performance'

;(async () => {
  if (import.meta.env.MODE === 'production') {
    Bugsnag.start({
      apiKey: import.meta.env.VITE_BUGSNAG_APIKEY,
      plugins: [new BugsnagPluginVue()]
    })
    BugsnagPerformance.start({ apiKey: import.meta.env.VITE_BUGSNAG_APIKEY })
  }
  
  dayjs.extend(calendar)
  dayjs.extend(relativeTime)
  dayjs.extend(updateLocale)
  dayjs.locale(zhCN)
  dayjs.updateLocale('zh-cn', {
    calendar: {
      lastDay: '[Yesterday at] LT',
      sameDay: '[Today at] LT',
      nextDay: '[Tomorrow at] LT',
      lastWeek: '[last] dddd [at] LT',
      nextWeek: 'dddd [a] LT',
      sameElse: 'L',
    },
  })

  const pinia = createPinia()

  const app = createApp(App)

  for (const name in menuIcons) {
    if (Object.prototype.hasOwnProperty.call(menuIcons, name)) {
      const icon = menuIcons[name]
      app.component(name, icon)
    }
  }

  app.use(LaravelPermissionToVueJS)
  app.use(pinia)
  app.config.globalProperties.$config = useConfigStore()
  console.log('start')
  const authStore = useAuthStore()
  authStore.isAuthorized && (await authStore.refreshCurrentUser().then(user => {
      // attach user to bugsnag
      Bugsnag.setUser(user?.id, user?.mobile, `[${user?.code}] ${user?.name}`)
  }))
  console.log('done')

  app
    .provide<String>('globalDatetimeFormat', 'YYYY-MM-DD HH:mm:ss')
    .use(router)
    .use(ConfigProvider)
    .use(ProLayout)
    .use(PageContainer)
    .mount('#app')


  if (import.meta.env.MODE === 'production') {
    app.use(
      Bugsnag.getPlugin('vue')
    )
  }
})()
