import { EnumDescription, type RecordId } from '.'
import type { IUser } from './User'

/**
 * Ticket
 */
export interface ITicket {
  attachments: string[]
  body: string
  classification: number
  content: IContent
  created_at?: Date
  creator?: IUser
  creator_id: RecordId
  follower_id: RecordId[]
  followers?: IUser[]
  id?: RecordId
  last_operation: Date | null
  nudge_count: number
  priority: Priority
  reopen_at: Date | null
  reopen_count: number
  responsible?: IUser
  responsible_id: RecordId
  schema: ISchemaTemplate
  schema_template_id: RecordId
  status: Status
  template_name: string
  ticket_number: string
  title: string
  updated_at?: Date | null
  operations?: []
}

export interface IRepairOrderTicket extends ITicket {
  pivot: {
    repair_order_id: RecordId
    repair_order_item_id: RecordId
    ticket_id: RecordId
  }
}

export interface IContent {
  [key: string]: any
}

export enum Priority {
  Low = 'low',
  Medium = 'medium',
  High = 'high',
  Urgent = 'urgent',
}

export enum Status {
  InProgress = 'in_progress',
  Completed = 'completed',
}

export enum Listing {
  MyCreation = 'my-creation',
  MyResponsible = 'my-responsible',
  Completed = 'completed',
  MyFollowings = 'my-followings',
  All = 'all',
}

export enum OperationType {
  Open = 'open',
  Nudge = 'nudge',
  Handover = 'handover',
  Close = 'close',
  Reopen = 'reopen',
  Reply = 'reply',
  Associate = 'associate',
  RequestApproval = 'request-approval',
  RequestApprovalCompleted = 'request-approval-completed'
}

export const StatusDescription = new Map<string, string>([
  [Status.InProgress, '已受理'],
  [Status.Completed, '已完成'],
])

export const PriorityDescription = new Map<string, string>([
  [Priority.Low, '低'],
  [Priority.Medium, '一般'],
  [Priority.High, '高'],
  [Priority.Urgent, '紧急'],
])

export const OperationTypeDescription = new Map<string, EnumDescription>([
  [OperationType.Open, new EnumDescription('创建')],
  [OperationType.Nudge, new EnumDescription('催单', { color: 'blue' })],
  [OperationType.Handover, new EnumDescription('转交', { color: 'yellow' })],
  [OperationType.Close, new EnumDescription('完结', { color: 'green' })],
  [OperationType.Reopen, new EnumDescription('重新开启')],
  [OperationType.Reply, new EnumDescription('回复')],
  [OperationType.Associate, new EnumDescription('关联')],
  [OperationType.RequestApproval, new EnumDescription('发起审批')],
  [OperationType.RequestApprovalCompleted, new EnumDescription('完成审批')]
])

/**
 *  Schema Template
 */
export interface ISchemaTemplate {
  created_at?: string
  fields: number[]
  id: number
  is_enabled: boolean
  name: string
  schema: ISchema[]
  updated_at?: string
}

export interface ISchema {
  fields: IFieldElement[]
  group: string
}

export interface IFieldElement {
  default: null | string
  field: IFieldField
  field_id: number
  help_text: null | string
  placeholder: string
  required: boolean
  required_for_completion: boolean
  sort: null | number
}

export interface IFieldField {
  as_table_column: number
  created_at: string
  id: number
  is_protected: boolean
  name: string
  options: null
  relation_model: null | string
  type: FieldType
  updated_at: string
}

export enum FieldType {
  TEXT = 'text',
  TEXTAREA = 'textarea',
  NUMERIC = 'numeric',
  SELECT = 'select',
  MULTISELECT = 'multiselect',
  CASCADE = 'cascade',
  DATETIME = 'datetime',
  RELATION_SELECT = 'relation_select',
  ATTACHMENT = 'attachment',
}

export enum TicketTemplateMapping {
  INSPECTION = 1,
  REPAIR = 2,
  PURCHASING = 3,
}