import { defineStore } from 'pinia'
import { api } from '@/services'
import type { User } from '@/services/api'
import Storage from '@/utils/storage'
import { computed, ref } from 'vue'
import {setupPermissionAndRole} from '@/utils/permissions'

export const userStorage = new Storage<User>('user')

export const isAuthorized = (): boolean => !!userStorage.get()

export const useAuthStore = defineStore('auth', () => {
  const user = ref(userStorage.get())
  const isAuthorized = computed(() => user.value !== null)

  function updateUser (userData?: User | null) {
    if (userData === undefined || userData === null) {
      userStorage.remove()
      user.value = null
    } else {
      userStorage.set(userData)
      user.value = Object.assign(user.value || {}, userData)
    }
  }

  function logout () {
    return api.users.logout().then(() => {
        userStorage.remove()
        user.value = null
        return Promise.resolve()
    })
  }

  const refreshCurrentUser = () => {
    return api.user.getCurrentUser()
      .then(({data}) => {
        userStorage.set(data)
        user.value = Object.assign(user.value || {}, data)
        setupPermissionAndRole(data.permissions, data.roles)
        return Promise.resolve(data)
      })
  }

  const operateUnreadNotificationCount = (n: number) => {
    user.value.unread_notification_count = user.value.unread_notification_count + n
    userStorage.set(user.value)
  }

  return {
    user,
    isAuthorized,
    refreshCurrentUser,
    operateUnreadNotificationCount,
    updateUser,
    logout
  }
})