import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router'
import type { RouteParams, RouteRecordRaw } from 'vue-router'
import BasicLayout from '../layouts/BasicLayout.vue'
import BlankLayout from '../layouts/BlankLayout.vue'
import Login from '../views/Login.vue'
import { isAuthorized } from '@/store/auth'
import { FormPageMode } from '@/types'
import { Listing, TicketTemplateMapping } from '@/types/Ticket'
import { cannot } from '@/utils/permissions'
import { useTitle } from '@vueuse/core'

// 'permissions.*' => [
//   'permissions.viewAny',
//   'permissions.view'],

// 'roles.*' => [
//   'roles.create',
//   'roles.viewAny',
//   'roles.update',
//   'roles.view',
//   'roles.delete'],

// 'accounts.*' => [
//   'accounts.assignManager',
//   'accounts.create',
//   'accounts.viewAuthorized',
//   'accounts.update',
//   'accounts.viewAuthorized',
// ],

// 'repairOrders.*' => [
//   'repairOrders.assignManager',
//   'repairOrders.create',
//   'repairOrders.viewAny',
//   'repairOrders.update',
//   'repairOrders.viewAuthorized',
// ],

// 'notifications.*' => [
//   'notifications.viewAuthorized',
//   'notifications.publish'
// ],

// 'financialManagement.*' => [
//   'financialManagement.overdueRepairOrders'
// ],

// 'users.*' => [
//   'users.update',
//   'users.create',
//   'users.viewAny',
// ],

// 'purchasingRequests.*' => [
//   'purchasingRequests.update',
//   'purchasingRequests.create',
//   'purchasingRequests.viewAny',
//   'purchasingRequests.viewAuthorized'
// ],

// 'ticket.tickets.*' => [
//   'ticket.tickets.update',
//   'ticket.tickets.create',
//   'ticket.tickets.viewAuthorized',
//   'ticket.tickets.viewAny',
// ],

// 'ticket.schema_templates.*' => [
//   'ticket.schema_templates.viewAny',
//   'ticket.schema_templates.update',
//   'ticket.schema_templates.create',
//   'ticket.schema_templates.delete',
// ],

// 'ticket.fields.*' => [
//   'ticket.fields.viewAny',
//   'ticket.fields.update',
//   'ticket.fields.create',
//   'ticket.fields.delete',
// ],

// 'equipment.*' => [
//   'equipment.viewAuthorized',
//   'equipment.update',
//   'equipment.create',
// ],

const ticketContentSetupForRepairing = [
  {
    title: '订单编号',
    key: 'content',
    width: 250,
  },
  {
    title: '预计完成时间',
    key: 'content',
    width: 250,
  },
  {
    title: '客户编号',
    key: 'content',
    width: 250,
  },
  {
    title: '关联机器',
    key: 'content',
    width: 250,
    displayer: data => {
      return data ? `[${data.code}] ${data.title}` : '/'
    },
  },
]

const ticketContentSetupForPurchasing = [
  {
    title: '关联工单',
    key: 'content',
    width: 250,
  },
  {
    title: '配件品牌',
    key: 'content',
    width: 250,
  },
  {
    title: '配件型号',
    key: 'content',
    width: 250,
  },
  {
    title: '封装方式',
    key: 'content',
    width: 250,
  },
  {
    title: '申购数量',
    key: 'content',
    width: 100,
  },
  {
    title: '申购状态',
    key: 'content',
    width: 250,
  },
  {
    title: '到货日期',
    key: 'content',
    width: 250,
  },
  {
    title: '是否合格',
    key: 'content',
    width: 250,
  },
  {
    title: '实际采购数量',
    key: 'content',
    width: 250,
  },
  {
    title: '实际领用数量',
    key: 'content',
    width: 250,
  },
]

export const routes: RouteRecordRaw[] = [
  {
    path: '/login',
    name: 'login',
    meta: { title: '登录', hideInMenu: true },
    component: Login,
  },
  {
    path: '/',
    name: 'index',
    meta: { title: '能恒技ERP' },
    component: BasicLayout,
    redirect: '/dashboard',
    children: [
      {
        path: '/dashboard',
        name: 'dashboard',
        meta: { title: '看板', icon: 'kanban-icon' },
        component: () => import('../views/admin/Dashboard.vue'),
      },
      {
        path: '/unauthorized',
        name: 'unauthorized',
        meta: { title: '权限不足，无法访问', hideInMenu: true },
        component: () => import('../views/Unauthorized.vue'),
      },
      {
        path: '/my-profile',
        name: 'myProfile',
        meta: { title: '个人设置', hideInMenu: true },
        component: () => import('../views/admin/MyProfile.vue'),
      },
      {
        path: '/notifications',
        redirect: '/notifications',
        meta: { title: '消息通知', hideChildInMenu: true, hideInMenu: true },
        component: BlankLayout,
        children: [
          {
            path: '/notifications',
            name: 'notifications.index',
            meta: {
              title: '消息通知',
              icon: 'kanban-icon',
              permissions: 'notifications.viewAuthorized',
            },
            component: () => import('../views/admin/notifications/Index.vue'),
          },
          {
            path: '/notifications/create',
            name: 'notifications.create',
            meta: {
              title: '发布通知',
              hideInMenu: true,
              hiddenHeaderContent: true,
              permissions: 'notifications.publish',
            },
            component: () => import('../views/admin/notifications/Create.vue'),
          },
        ],
      },
      {
        path: '/accounts',
        redirect: '/accounts',
        name: 'accounts',
        meta: {
          title: '客户管理',
          icon: 'kehuguanli-icon',
          hideChildInMenu: true,
          permissions: 'accounts.viewAuthorized',
        },
        component: BlankLayout,
        children: [
          {
            path: '/accounts',
            name: 'accounts.index',
            meta: { title: '客户列表', permissions: 'accounts.viewAuthorized' },
            component: () => import('../views/admin/accounts/Index.vue'),
          },
          {
            path: '/accounts/create',
            name: 'accounts.create',
            props: { formMode: FormPageMode.NEW },
            meta: {
              title: '创建客户',
              hideInMenu: true,
              hiddenHeaderContent: true,
              permissions: 'accounts.create',
            },
            component: () => import('../views/admin/accounts/Form.vue'),
          },
          {
            path: '/accounts/:id/edit',
            name: 'accounts.edit',
            props: { formMode: FormPageMode.EDIT },
            meta: {
              title: '修改客户',
              hideInMenu: true,
              hiddenHeaderContent: true,
              permissions: 'accounts.update',
            },
            component: () => import('../views/admin/accounts/Form.vue'),
          },
        ],
      },
      {
        path: '/sales-leads',
        redirect: '/sales-leads',
        name: 'salesLeads',
        meta: {
          title: '客户线索',
          icon: 'kehuguanli-icon',
          hideChildInMenu: true,
          permissions: 'salesLeads.viewAuthorized',
        },
        component: BlankLayout,
        children: [
          {
            path: '/sales-leads',
            name: 'salesLeads.index',
            meta: { title: '客户列表', permissions: 'salesLeads.viewAuthorized' },
            component: () => import('../views/admin/salesLeads/Index.vue'),
          },
        ],
      },
      {
        path: '/equipment',
        redirect: '/equipment',
        name: 'equipment',
        meta: {
          title: '机器管理',
          icon: 'jiqiguanli-icon',
          hideChildInMenu: true,
          permissions: 'equipment.viewAuthorized',
        },
        component: BlankLayout,
        children: [
          {
            path: '/equipment',
            name: 'equipment.index',
            meta: { title: '机器列表', permissions: 'equipment.viewAuthorized' },
            component: () => import('../views/admin/equipment/Index.vue'),
          },
          {
            path: '/equipment/create',
            name: 'equipment.create',
            props: { formMode: FormPageMode.NEW },
            meta: {
              title: '创建机器',
              hideInMenu: true,
              hiddenHeaderContent: true,
              permissions: 'equipment.create',
            },
            component: () => import('../views/admin/equipment/Form.vue'),
          },
          {
            path: '/equipment/:id/edit',
            name: 'equipment.edit',
            props: { formMode: FormPageMode.EDIT },
            meta: {
              title: '修改机器',
              hideInMenu: true,
              hiddenHeaderContent: true,
              permissions: 'equipment.update',
            },
            component: () => import('../views/admin/equipment/Form.vue'),
          },
        ],
      },
      {
        path: '/repair-orders',
        redirect: '/repair-orders',
        name: 'repairOrders',
        meta: {
          title: '维修订单',
          icon: 'weixiudingdan-icon',
          hideChildInMenu: true,
          permissions: 'repairOrders.viewAuthorized',
        },
        component: BlankLayout,
        children: [
          {
            path: '/repair-orders',
            name: 'repairOrders.index',
            meta: { title: '维修订单列表', permissions: 'repairOrders.viewAuthorized' },
            component: () => import('../views/admin/repairOrders/Index.vue'),
          },
          {
            path: '/repair-orders/create/:warranty?/:parentId?',
            name: 'repairOrders.create',
            props: route => ({
              warranty: route.params.warranty,
              parentId: route.params.parentId ? Number(route.params.parentId) : null,
              formMode: FormPageMode.NEW,
            }),
            meta: {
              title: '创建维修订单',
              hideInMenu: true,
              hiddenHeaderContent: true,
              permissions: 'repairOrders.create',
            },
            component: () => import('../views/admin/repairOrders/Form.vue'),
          },
          {
            path: '/repair-orders/:id/edit',
            name: 'repairOrders.edit',
            props: { formMode: FormPageMode.EDIT },
            meta: {
              title: '修改维修订单',
              hideInMenu: true,
              hiddenHeaderContent: true,
              permissions: 'repairOrders.update',
            },
            component: () => import('../views/admin/repairOrders/Form.vue'),
          },
          {
            path: '/repair-orders/:id/view',
            name: 'repairOrders.view',
            meta: { title: '维修订单详情', hideInMenu: true, hiddenHeaderContent: true },
            component: () => import('../views/admin/repairOrders/View.vue'),
          },
        ],
      },
      {
        path: '/repair-orders/overdues',
        name: 'repairOrders.overdues',
        meta: {
          title: '财务管理',
          icon: 'weixiudingdan-icon',
          permissions: 'financialManagement.overdueRepairOrders',
        },
        component: () => import('../views/admin/repairOrders/Overdues.vue'),
      },
      {
        path: '/tickets',
        redirect: '/tickets/my-responsible',
        name: 'tickets',
        meta: {
          title: '工单管理',
          icon: 'gongdanguanli-icon',
          permissions: 'ticket.tickets.viewAuthorized',
        },
        component: BlankLayout,
        children: [
          {
            path: '/tickets/my-responsible',
            name: 'tickets.index.myResponible',
            meta: { title: '我受理的工单' },
            props: {
              listing: Listing.MyResponsible,
              schema: [TicketTemplateMapping.INSPECTION, TicketTemplateMapping.REPAIR],
              contentSetup: ticketContentSetupForRepairing,
              permissions: 'ticket.tickets.viewAuthorized',
            },
            component: () => import('../views/admin/tickets/Index.vue'),
          },
          {
            path: '/tickets/my-creation',
            name: 'tickets.index.myCreation',
            meta: { title: '我创建的工单' },
            props: {
              listing: Listing.MyCreation,
              schema: [TicketTemplateMapping.INSPECTION, TicketTemplateMapping.REPAIR],
              contentSetup: ticketContentSetupForRepairing,
              permissions: 'ticket.tickets.viewAuthorized',
            },
            component: () => import('../views/admin/tickets/Index.vue'),
          },
          {
            path: '/tickets/completed',
            name: 'tickets.index.completed',
            meta: { title: '我完成的工单' },
            props: {
              listing: Listing.Completed,
              schema: [TicketTemplateMapping.INSPECTION, TicketTemplateMapping.REPAIR],
              contentSetup: ticketContentSetupForRepairing,
              permissions: 'ticket.tickets.viewAuthorized',
            },
            component: () => import('../views/admin/tickets/Index.vue'),
          },
          {
            path: '/tickets/my-followings',
            name: 'tickets.index.myFollowings',
            meta: { title: '我关注的工单' },
            props: {
              listing: Listing.MyFollowings,
              schema: [TicketTemplateMapping.INSPECTION, TicketTemplateMapping.REPAIR],
              contentSetup: ticketContentSetupForRepairing,
              permissions: 'ticket.tickets.viewAuthorized',
            },
            component: () => import('../views/admin/tickets/Index.vue'),
          },
          {
            path: '/tickets/all',
            name: 'tickets.index.all',
            meta: { title: '全部工单', permissions: 'ticket.tickets.viewAny' },
            props: {
              listing: Listing.All,
              schema: [TicketTemplateMapping.INSPECTION, TicketTemplateMapping.REPAIR],
              contentSetup: ticketContentSetupForRepairing,
              permissions: 'ticket.tickets.viewAuthorized',
            },
            component: () => import('../views/admin/tickets/Index.vue'),
          },
          {
            path: '/tickets/create/:template_id?',
            name: 'tickets.create',
            props: route => ({
              template_id: Number(route.params.template_id),
              formMode: FormPageMode.NEW,
            }),
            meta: {
              title: '创建工单',
              hideInMenu: true,
              hiddenHeaderContent: true,
              permissions: 'ticket.tickets.create',
            },
            component: () => import('../views/admin/tickets/Form.vue'),
          },
          {
            path: '/tickets/:id/edit',
            name: 'tickets.edit',
            props: { formMode: FormPageMode.EDIT },
            meta: {
              title: '修改工单',
              hideInMenu: true,
              hiddenHeaderContent: true,
              permissions: 'ticket.tickets.update',
            },
            component: () => import('../views/admin/tickets/Form.vue'),
          },
          {
            path: '/tickets/:id',
            name: 'tickets.show',
            meta: { title: '工单详情', hideInMenu: true, hiddenHeaderContent: true },
            component: () => import('../views/admin/tickets/Show.vue'),
          },
        ],
      },
      {
        path: '/purchasing-requests',
        redirect: '/purchasing-requests/my-creation',
        name: 'purchasingRequests',
        meta: {
          title: '采购管理',
          icon: 'caigouguanli-icon',
        },
        component: BlankLayout,
        children: [
          {
            path: '/purchasing-requests/all',
            name: 'purchasingRequests.index.all',
            props: route => ({ listing: 'all' }),
            meta: { title: '全部采购申请单', permissions: 'purchasingRequests.manage' },
            component: () => import('../views/admin/purchasing-requests/Index.vue'),
          },
          {
            path: '/purchasing-requests/my-creation',
            name: 'purchasingRequests.index.myCreation',
            props: route => ({ listing: 'my-creation' }),
            meta: { title: '我提交的采购申请单' },
            component: () => import('../views/admin/purchasing-requests/Index.vue'),
          },
          {
            path: '/purchasing-requests/create',
            name: 'purchasingRequests.create',
            props: route => ({ query: route.query.q, formMode: FormPageMode.NEW }),
            meta: {
              title: '创建采购申请单',
              hideInMenu: true,
              hiddenHeaderContent: true,
            },
            component: () => import('../views/admin/purchasing-requests/Form.vue'),
          },
          {
            path: '/purchasing-requests/:id',
            name: 'purchasing-requests.show',
            meta: { title: '采购申请单详情', hideInMenu: true, hiddenHeaderContent: true },
            component: () => import('../views/admin/purchasing-requests/Show.vue'),
          },
        ],
      },
      {
        path: '/approval-requests',
        redirect: '/approval-requests/my-creation',
        name: 'approvalRequests',
        meta: {
          title: '审批管理',
          icon: 'caigouguanli-icon',
        },
        component: BlankLayout,
        children: [
          {
            path: '/approval-requests/my-request',
            name: 'approvalRequests.index.myRequest',
            props: route => ({ listing: 'my-request' }),
            meta: { title: '我提交的审批申请单', permissions: 'approvalRequests.viewAuthorized' },
            component: () => import('../views/admin/approval-requests/Index.vue'),
          },
          {
            path: '/approval-requests/my-responsible',
            name: 'approvalRequests.index.myResponsible',
            props: route => ({ listing: 'my-responsible' }),
            meta: { title: '我负责的审批申请单',  permissions: 'approvalRequests.manage' },
            component: () => import('../views/admin/approval-requests/Index.vue'),
          },
          {
            path: '/approval-requests/:id',
            name: 'approval-requests.show',
            meta: { title: '审批申请单详情', hideInMenu: true, hiddenHeaderContent: true },
            component: () => import('../views/admin/approval-requests/Show.vue'),
          },
        ],
      },
      {
        path: '/users',
        redirect: '/users',
        name: 'users',
        meta: {
          title: '用户管理',
          icon: 'yonghuguanli-icon',
          // hideChildInMenu: true,
          permissions: 'users.viewAny',
        },
        component: BlankLayout,
        children: [
          {
            path: '/users',
            name: 'users.index',
            meta: { title: '用户列表', permissions: 'users.viewAny' },
            component: () => import('../views/admin/users/Index.vue'),
          },
          {
            path: '/users/:id/edit',
            name: 'users.edit',
            props: { formMode: FormPageMode.EDIT },
            meta: {
              title: '修改用户',
              hideInMenu: true,
              hiddenHeaderContent: true,
              permissions: 'users.update',
            },
            component: () => import('../views/admin/users/Form.vue'),
          },
          {
            path: '/users/create',
            name: 'users.create',
            props: { formMode: FormPageMode.NEW },
            meta: {
              title: '创建用户',
              hideInMenu: true,
              hiddenHeaderContent: true,
              permissions: 'users.create',
            },
            component: () => import('../views/admin/users/Form.vue'),
          },
          {
            path: '/users/third-party-oauth',
            name: 'users.oauth',
            meta: { title: '第三方用户列表', permissions: 'users.oauth.viewAny' },
            component: () => import('../views/admin/users/oauth/Index.vue'),
          },
        ],
      },
      // {
      //   path: '/admin',
      //   name: 'admin',
      //   meta: { title: '管理页', icon: 'icon-tuijian'},
      //   component: BlankLayout,
      //   redirect: () => ({ name: 'welcome' }),
      //   children: [
      //     {
      //       path: 'page-1',
      //       name: 'page1',
      //       meta: { title: '一级页面' },
      //       component: () => import('../views/admin/PageInfo.vue'),
      //     },
      //     {
      //       path: 'page-2',
      //       name: 'page2',
      //       meta: { title: '二级页面' },
      //       component: () => import('../views/admin/PageTypography.vue'),
      //     },
      //     {
      //       path: 'dynamic-match/:id(\\d+)',
      //       name: 'dynamic-match',
      //       // 路由 path 默认参数再 meta.params 里
      //       meta: { title: '动态参数页面', params: { id: 1 } },
      //       component: () => import('../views/admin/DynamicMatch.vue'),
      //     },
      //   ],
      // },
      // {
      //   path: '/version',
      //   name: 'version',
      //   meta: { title: 'Version', icon: 'icon-antdesign' },
      //   component: () => import('../views/Detail.vue'),
      // },
    ],
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

router.beforeEach(async (to, from) => {
  useTitle(`${to.meta.title} | 能恒技ERP系统 - 广东能恒技精密电子科技有限公司`)
  if (
    // make sure the user is authenticated
    !isAuthorized() &&
    to.name !== 'login'
  ) {
    // redirect the user to the login page
    return { name: 'login' }
  }

  if (to.meta.permissions && cannot(to.meta.permissions)) {
    return { name: 'unauthorized' }
  }

  window.scrollTo(0, 0)
})

export default router
