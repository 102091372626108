<template>
  <div class="flex h-screen">
    <div class="w-[42vw] bg-cover" :style="`background-image: url(&quot;${loginBg}&quot;)`">
      <div class="m-10 w-[138px]">
        <img src="@/assets/logo-trans-bg-gray-text.png" alt="能恒技LOGO" />
      </div>
    </div>
    <div class="w-[58vw] h-screen flex items-center">
      <div class="mx-auto w-96">
        <h1 class="text-xl text-center mb-12">欢迎登录能恒技ERP系统</h1>
        <FormError ref="formErrorRef" v-model:error-bag="errorBag" :form-model="formState.model" />
        <a-form name="basic" v-bind="formState" @finish="onFinish">
          <a-form-item name="mobile">
            <a-input
              ref="autoFocusItem"
              size="large"
              placeholder="请输入登录账号"
              v-model:value="formState.model.mobile"
            >
              <template #addonBefore>
                <UserOutlined class="!text-gray-500 p-1" aria-label="用户名" />
              </template>
            </a-input>
          </a-form-item>

          <a-form-item name="password">
            <a-input-password
              size="large"
              placeholder="请输入登录密码"
              v-model:value="formState.model.password"
            >
              <template #addonBefore>
                <LockOutlined class="!text-gray-500 p-1" />
              </template>
            </a-input-password>
          </a-form-item>

          <a-form-item name="remember">
            <a-checkbox v-model:checked="formState.model.remember">记住密码</a-checkbox>
          </a-form-item>

          <a-button
            class="w-full"
            size="large"
            type="primary"
            html-type="submit"
            :loading="formState.loading"
          >
            登录
          </a-button>
        </a-form>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { FormProps, message } from 'ant-design-vue';
import { PageContainer as PageContainer } from '@ant-design-vue/pro-layout';
import { defineComponent, reactive, ref, computed, onMounted, nextTick } from 'vue';
import { useRouter } from 'vue-router';
// import AuthService from '../services/AuthSerivce.js';
import { api, isFetchError } from '../services';
import type { User } from '@/services/api';
import Storage from '@/utils/storage';
import { Form } from 'ant-design-vue';
import { FormError, initErrorBag } from '@/components/FormError';
import { useAuthStore } from '@/store/auth';
import { LockOutlined, UserOutlined } from '@ant-design/icons-vue';
import loginBg from '@/assets/login-bg.png';
import {useConfigStore} from '@/store/config'

const useForm = Form.useForm;

const router = useRouter();

const autoFocusItem = ref();
const formErrorRef = ref();
const errorBag = initErrorBag();
const loginButton = ref(null);
const $config = useConfigStore()
let geetestInstance;

const setFocus = () => autoFocusItem.value.focus();

const formState = reactive<FormProps>({
  loading: false,
  model: {
    mobile: '',
    password: '',
    remember: true,
  },
  rules: {
    mobile: [{ required: true, message: '请输入你的登录手机号' }],
    password: [{ required: true, message: '请输入你的密码' }],
  },
});

onMounted(() => {
  nextTick(setFocus);
  intiGeetest();
});

const intiGeetest = () => {
  initGeetest4(
    {
      captchaId: $config.geetestId,
      product: 'bind',
    },
    function (captcha) {
      // captcha为验证码实例
      console.log({ captcha });
      captcha
        .onReady(() => geetestInstance = captcha)
        .onSuccess(() => {
          var result = captcha.getValidate();
          if (!result) {
            message.error('请先完成人机验证')
          }
          toLogin({captcha: result})
        })
        .onError(_ => {});
    },
  );
};

const onFinish = async (values: any) => {
  if (!geetestInstance) {
    return message.error('无法调出人机交互验证，请稍后再试。')
  }
  geetestInstance.showCaptcha();
};

const toLogin = (additional = {}) => {
  const data = {
    ...formState.model,
    ...additional,
  };

  formState.loading = true;
  api.users
    .login(data)
    .then(useAuthStore().refreshCurrentUser)
    .then(user => {
      window.Laravel.jsPermissions.permissions = user.permissions;
      window.Laravel.jsPermissions.roles = user.roles;
      router.replace('/');
    })
    .catch(({ error }) => {
      formErrorRef.value.addErrors(error);
      throw error;
    })
    .finally(() => (formState.loading = false));
};
</script>
