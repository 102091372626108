export type RecordId = number | string
export enum FormPageMode {
    NEW = 'new',
    EDIT = 'edit'
}

export const YesNoDescription = new Map<any, string>([
    [0, '否'],
    [1, '是'],
    ['0', '否'],
    ['1', '是'],
    [false, '否'],
    [true, '是'],
    ['no', '否'],
    ['yes', '是'],
  ])
  
export interface StoreShowMethodParams {
    useCache?: Boolean
}


export class EnumDescription {
    description: string
    params: {}
    constructor(description: string, params = {}) {
        this.description = description
        this.params = params
    }

    toString() {
        return this.description
    }

    ofParam(property: string) {
        return this.params[property]
    }
    

}