<template>
<div class="">
  <a-alert
  v-if="hasError"
  :message="errorBag.message"
  type="error"
  show-icon
  class="!mb-2"
>
<template #description>
  <div v-for="(field, index) in errorBag.errors" :key="index">
    <li v-for="(error, index) in field" :key="index">
      {{ error }}
    </li>
  </div>
</template>
</a-alert>
</div>
</template>

<script lang="ts" setup>
import { defineProps, defineEmits, computed, watch, reactive } from "vue";

const props = defineProps({
    errorBag: Object,
    message: String,
    formModel: Object
})

const emits = defineEmits(['update:errorBag', 'update:message'])

const defaultErrorBag = {
  message: '错误',
  errors: {}
}

const hasError = computed(() => {
  return Object.keys(props.errorBag.errors).length > 0
})

const addErrors = (error) => {
  emits('update:errorBag', Object.assign(props.errorBag, error))
};

watch(() => props.formModel, () => {
  if (hasError.value === true) {
    console.log('aa')
    const errorBag = Object.assign(props.errorBag, defaultErrorBag)
    emits('update:errorBag', errorBag)
    emits('update:message', "" + Math.random())
  }
}, { deep: true})

defineExpose({addErrors})

</script>
