<template>
  <ConfigProvider>
    <a-config-provider :locale="zhCN">
      <a-spin :spinning="isLoading('system')">
        <router-view />
      </a-spin>
    </a-config-provider>
  </ConfigProvider>
  <a-modal />
  <!-- loads a-modal -->
</template>

<script setup lang="ts">
import { ConfigProvider } from 'ant-design-vue';
import { useUserTheme } from './hooks/useTheme';
import zhCN from 'ant-design-vue/es/locale/zh_CN';
import useLoading from './hooks/useLoading';

const { isLoading } = useLoading();

useUserTheme();
7;
</script>

<style lang="less">
#app {
  height: 100%;
}

@media print {
  body {
    -webkit-print-color-adjust: exact;
  }
  .ant-layout-has-sider > *:not(.ant-layout), .ant-layout-sider, .ant-layout-header {
    display: none !important;
  }
  .ant-affix {
    position: relative !important;
    top: initial !important;
    left: initial !important;
    right: initial !important;
    bottom: initial !important;
  }

  .container {
    max-width: 100%;
  }
}

.ant-pro-sider {
  z-index: 20;
}

.ant-table-thead > tr > th {
  background-color: #f7f7f7;
}

.anticon {
  vertical-align: 0.125rem;
}

.ant-image-preview-wrap > div > div.ant-image-preview-content > div > div > img {
  display: inline;
}

.slide-left-enter-active,
.slide-left-leave-active,
.slide-right-enter-active,
.slide-right-leave-active {
  transition-duration: 0.5s;
  transition-property: height, opacity, transform;
  transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  overflow: hidden;
}

.slide-left-enter,
.slide-right-leave-active {
  opacity: 0;
  transform: translate(2em, 0);
}

.slide-left-leave-active,
.slide-right-enter {
  opacity: 0;
  transform: translate(-2em, 0);
}

.ant-dropdown-link {
  padding: 4px 15px;
}

.priority-low {
  color: #9d9d9d;
}

.priority-medium {
  color: currentColor;
}

.priority-high {
  color: var(--ant-warning-color);
}

.priority-urgent {
  color: var(--ant-error-color);
}

.color-indicator {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 8px;
  background: #9d9d9d;
  display: inline-block;
}

[class^='approval-request-state-'],
[class^='pay-state-'],
[class^='inspection-state-'],
[class^='repair-state-'],
[class^='state-'],
[class^='color-indicator-'] {
  .color-indicator();
}


.approval-request-state-rejected,
.pay-state-未收款,
.state-returned,
.state-cancelled,
.color-indicator-red {
  background: var(--ant-error-color);
}

.pay-state-已部分收款,
.inspection-state-idle,
.repair-state-idle,
.color-indicator-yellow {
  background: var(--ant-warning-color);
}

.approval-request-state-approved,
.pay-state-已收款,
.inspection-state-completed,
.repair-state-completed,
.state-completed,
.color-indicator-green {
  background: var(--ant-success-color);
}

.approval-request-state-pending,
.inspection-state-inspecting,
.repair-state-repairing,
.state-in_progress,
.color-indicator-blue {
  background: var(--ant-primary-color);
}

.required-items:after {
  color: #ff4d4f;
  content: "*";
  display: inline-block;
  font-family: SimSun,sans-serif;
  font-size: 14px;
  line-height: 1;
  margin-left: 4px;
}
</style>
