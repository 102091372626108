const checkPermission = permission => {
  const permissions = window?.Laravel.jsPermissions.permissions || []
  if (permissions.includes('*')) {
    return true
  }
  if (permissions.includes(permission)) {
    return true
  }
  const segments = permission.split('.')
  let tempSegments = []
  const wildcardPermissions = segments.reduce((acc, occ, index) => {
    if (index <= segments.length - 1) {
      tempSegments.push(occ)
      acc.push(tempSegments.join('.') + '.*')
    }
    return acc
  }, [])

  for (let index = 0; index < wildcardPermissions.length; index++) {
    if (permissions.includes(wildcardPermissions[index])) {
      return true
    }
  }

  return false
}

export const can = function (value) {
  if (!value) {
    return true
  }
  if (window.Laravel.jsPermissions == 0) {
    return false
  }
  let permissions = window.Laravel.jsPermissions.permissions

  let _return = false
  if (!Array.isArray(permissions)) {
    return false
  }

  if (permissions.includes('*')) {
    return true
  }

  if (Array.isArray(value)) {
	value = value.join('|')
  }

  if (value.includes('|')) {
    value.split('|').forEach(function (item) {
      if (checkPermission(item.trim())) {
        _return = true
      }
    })
  } else if (value.includes('&')) {
    _return = true
    value.split('&').forEach(function (item) {
      if (!checkPermission(item.trim())) {
        _return = false
      }
    })
  } else {
    _return = checkPermission(value.trim())
  }
  return _return
}

export const cannot = value => {
  return !can(value)
}

export const is = function (value) {
  if (window.Laravel.jsPermissions == 0) {
    return false
  }
  let roles = window.Laravel.jsPermissions.roles
  let _return = false
  if (!Array.isArray(roles)) {
    return false
  }
  if (value.includes('|')) {
    value.split('|').forEach(function (item) {
      if (roles.includes(item.trim())) {
        _return = true
      }
    })
  } else if (value.includes('&')) {
    _return = true
    value.split('&').forEach(function (item) {
      if (!roles.includes(item.trim())) {
        _return = false
      }
    })
  } else {
    _return = roles.includes(value.trim())
  }
  return _return
}

export const setupPermissionAndRole = (permissions = [], roles = []) => {
  window.Laravel.jsPermissions.permissions = permissions
  window.Laravel.jsPermissions.roles = roles
}

export default {
  install: (app, options) => {
    if (window.Laravel === undefined) {
      window.Laravel = {}
    }

    window.Laravel.jsPermissions = {
      permissions: [],
      roles: [],
    }

    app.config.globalProperties.can = can

    app.config.globalProperties.cannot = cannot

    app.config.globalProperties.is = is
  },
}
