import { api } from "@/services";
import type { OptionProps } from "ant-design-vue/lib/select";
import { defineStore } from "pinia";
import { onMounted } from 'vue'

export const useConfigStore = defineStore('config', () => {
    const dateFormat = ref('YYYY-MM-DD')
    const timeFormat = ref('HH:mm:ss')
    const codePattern = /^[abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789\-_#\.]+$/
    const datetimeFormat = computed(() => `${dateFormat.value} ${timeFormat.value}`)
    const geetestId = import.meta.env.VITE_GEETEST_ID

    const getCompanies = () => {
        return api.misc.companies().then(({data}) => _companies.value = data)
    }

    const _companies = ref<OptionProps>([])

    getCompanies()

    const companies = computed(() => _companies)

    return {
        companies,
        geetestId,
        getCompanies,
        codePattern,
        dateFormat,
        timeFormat,
        datetimeFormat
    }
})