const loadings = reactive<Record<string, boolean>>({})

export default () => {
  const isLoading: (key: string) => boolean = key => {
    return !!loadings[key]
  }

  const stopLoading: (key: string) => void = key => {
    loadings[key] = false
  }

  const startLoading: (key: string) => void = (key: string) => {
    loadings[key] = true
  }

  const toggleLoading: (key: string) => boolean = key => {
    return (loadings[key] = !loadings[key])
  }

  const withLoading = (key: string, promise: Promise<any> | Function) => {
    startLoading(key)

    const handlePromise = (promise) => {
      return promise.finally(() => stopLoading(key))
    }

    const handleFunction = () => {
      const result = promise()

      if (result instanceof Promise) {
        return handlePromise(result)
      }
      
      stopLoading(key)
      return result
    }

    if (promise instanceof Promise) {
      return handlePromise(promise)
    }

    if (promise instanceof Function) {
      return handleFunction()
    }    
  }

  return {
    withLoading,
    toggleLoading,
    stopLoading,
    startLoading,
    isLoading,
    loadings: readonly(loadings),
  }
}
